import React from 'react';
import './blog.css';
import {Article} from '../../components';
import {blog01, blog02, blog03, blog04, blog05} from './imports'

const Blog = () => {
  return (
    <div className='gpt3__blog section__padding' id='blog'>
      <div className='gpt3__blog-heading'>
        <h1 className='gradient__text'>A lot is happening, We are blogging about it.</h1>
      </div>
      <div className='gpt3__blog-container'>
        <div className='gpt3__blog-container_groupA'>
          <Article imgUrl={blog01} date='June 21 , 2024' title='GPT-3 and Open  AI is the future. Let us exlore how it is?'/>
        </div>
        <div className='gpt3__blog-container_groupB'>
          <Article imgUrl={blog02} date='June 21 , 2024' title='Why is GPT-3 a Game Changer in AI?'/>
          <Article imgUrl={blog03} date='June 21 , 2024' title='How Will GPT-3 Shape the AI Horizon?'/>
          <Article imgUrl={blog04} date='June 21 , 2024' title='What Impact Will GPT-3 Have on AI?'/>
          <Article imgUrl={blog05} date='June 21 , 2024' title='How is GPT-3 Pioneering the Future of AI?'/>
        </div>
      </div>
    </div>
  )
}

export default Blog
